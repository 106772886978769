import { IAssociationLog } from './../../pages/associations/shared/interface/association-detail.interface';
export const SAMPLE_DOCUMENTS: any[] = [
    {
        id: "523d6a1f-a32d-4b03-9282-b8ab12696c17",
        propertyId: null,
        parent: "documents",
        path: "documents/bids",
        name: "Bids",
        isPrivate: false,
        createdAt: "2024-03-23T23:19:20.279+00:00",
        updatedAt: "2024-03-23T23:19:20.279+00:00",
        importId: "200",
        documentFolderType: "folder"
    },
    {
        id: "7e541372-ff1e-4a9e-9475-832e819ca758",
        propertyId: "6368568e-fa93-4f10-b717-a4356f148db4",
        model: null,
        modelId: null,
        groupId: null,
        groupName: null,
        roomId: null,
        roomName: null,
        customerId: null,
        customerName: null,
        userId: null,
        userName: null,
        type: "F",
        subtype: null,
        extension: "PDF",
        mimeType: "application/pdf",
        size: 276112,
        url: "https://manager.libertycm.com/restored/F_eclp_220813_1631551215.7848.pdf",
        thumbUrl: null,
        path: "documents",
        name: "eclp bylaws.pdf",
        diskName: "F_eclp_220813_1631551215.7848.pdf",
        date: "2021-09-13T00:00:00.000Z",
        memo: "",
        isOptimized: false,
        isPrivate: false,
        isFavorite: false,
        status: 1,
        createdAt: "2024-03-23T23:40:58.200+00:00",
        updatedAt: "2024-03-23T23:40:58.200+00:00",
        importId: "1305735",
        documentFolderType: "document",
        statusName: "Active"
    },
    {
        id: "8dc7f302-3d39-46be-a7f0-271ce903644b",
        propertyId: null,
        parent: "documents",
        path: "documents/permits",
        name: "Permits",
        isPrivate: false,
        createdAt: "2024-03-23T23:19:20.323+00:00",
        updatedAt: "2024-03-23T23:19:20.323+00:00",
        importId: "207",
        documentFolderType: "folder"
    },
    {
        id: "7e541372-ff1e-4a9e-9475-832e819ca758",
        size: 276112,
        url: "https://manager.libertycm.com/restored/F_eclp_220813_1631551215.7848.docx",
        thumbUrl: null,
        path: "documents",
        name: "eclp bylaws.pdf",
        diskName: "F_eclp_220813_1631551215.7848.pdf",
        date: "2021-09-13T00:00:00.000Z",
        memo: "",
        isOptimized: false,
        isPrivate: false,
        isFavorite: false,
        status: 1,
        createdAt: "2024-03-23T23:40:58.200+00:00",
        updatedAt: "2024-03-23T23:40:58.200+00:00",
        importId: "1305735",
        documentFolderType: "document",
        statusName: "Active"
    },
    {
        id: "b130521d-7591-46a4-8d20-03f4c33759ea",
        propertyId: null,
        parent: "documents",
        path: "documents/plats",
        name: "Plats",
        isPrivate: false,
        createdAt: "2024-03-23T23:19:20.329+00:00",
        updatedAt: "2024-03-23T23:19:20.329+00:00",
        importId: "208",
        documentFolderType: "folder"
    },

    {
        id: "30ed2ef9-232e-4816-b43a-87f721ab7333",
        propertyId: "6368568e-fa93-4f10-b717-a4356f148db4",
        model: null,
        modelId: null,
        groupId: null,
        groupName: null,
        roomId: null,
        roomName: null,
        customerId: null,
        customerName: null,
        userId: null,
        userName: null,
        type: "F",
        subtype: null,
        extension: "PDF",
        mimeType: "application/pdf",
        size: 1400961,
        url: "https://manager.libertycm.com/restored/F_eclp_893229_1631551215.8124.pdf",
        thumbUrl: null,
        path: "documents",
        name: "eclp convenants.pdf",
        diskName: "F_eclp_893229_1631551215.8124.pdf",
        date: "2021-09-13T00:00:00.000Z",
        memo: "",
        isOptimized: false,
        isPrivate: false,
        isFavorite: false,
        status: 1,
        createdAt: "2024-03-23T23:40:58.212+00:00",
        updatedAt: "2024-03-23T23:40:58.212+00:00",
        importId: "1305736",
        documentFolderType: "document",
        statusName: "Active"
    },
    {
        id: "09f84ba4-fb08-4e95-84c0-0f697206b152",
        propertyId: null,
        parent: "documents",
        path: "documents/resolutions",
        name: "Resolutions",
        isPrivate: false,
        createdAt: "2024-03-23T23:19:20.335+00:00",
        updatedAt: "2024-03-23T23:19:20.335+00:00",
        importId: "209",
        documentFolderType: "folder"
    }
]

export const SAMPLE_EMAIL = [
    {
        id: "f60d35a5-be76-49e6-8793-954f74f2a8fa",
        propertyId: "7bc599a1-3563-424c-8d58-d9793d83f897",
        type: "2",
        modelId: "94dd67a7-ef04-4ec8-94d8-245f970a5176",
        createdUserId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
        createdUserName: "System",
        assignedUserId: null,
        assignedUserName: null,
        date: "2024-03-11T00:00:00.000Z",
        title: null,
        text: "Email from <b>Nicole Miller</b> <i>(nicole.miller@libertycm.com)</i><br>to <b>Thomas Edward Darity</b> <i>(darityreporting@hotmail.com)</i> <br><b>Subject:</b> Modification application receipt for 306 Annslee Circle<br><br><p>Re: Modification at 306 Annslee Circle in the Annslee Lakes subdivision</p>Dear Thomas Edward Darity,<br><br>We have received your application. Please note that no work can commence unless and until you receive an email stating that your application has been approved. Your associations Architectural Control Committee will be reviewing your application. We will provide you with their decision once received. You can view the current status of your request in real-time 24/7 by logging into the homeowner account portal at https://www.libertycm.com .\n\n</p>",
        status: 1,
        createdAt: "2024-03-11T16:23:56.000+00:00",
        updatedAt: "2024-03-11T16:23:56.000+00:00",
        importId: "1693676",
        documents: []
    },
    {
        id: "15c74557-d9b6-44ea-a59f-51eaffc42938",
        propertyId: "7bc599a1-3563-424c-8d58-d9793d83f897",
        type: "2",
        modelId: "94dd67a7-ef04-4ec8-94d8-245f970a5176",
        createdUserId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
        createdUserName: "System",
        assignedUserId: null,
        assignedUserName: null,
        date: "2024-03-11T00:00:00.000Z",
        title: null,
        text: "Email from <b>Nicole Miller</b> <i>(nicole.miller@libertycm.com)</i><br>to <b>Architectural Committee</b><br><b>Subject:</b> ARC guidance on Modification Request - 306 Annslee Circle - <br><br>Replace Fence - Already Completed \r\n\r\nPlease find the request at <a href='https://committee.libertycm.com/architectural.php>https://committee.libertycm.com/architectural.php</a> \r\n\r\nIf you are having problems with the link, copy and paste <b>committee.libertycm.com</b> into your browser location.\r\n\r\n\r\n\r\n",
        status: 1,
        createdAt: "2024-03-11T16:23:59.000+00:00",
        updatedAt: "2024-03-11T16:23:59.000+00:00",
        importId: "1693677",
        documents: []
    },
    {
        id: "739dd6e0-ee83-4e83-88ba-680abd84dc77",
        propertyId: "7bc599a1-3563-424c-8d58-d9793d83f897",
        type: "2",
        modelId: "94dd67a7-ef04-4ec8-94d8-245f970a5176",
        createdUserId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
        createdUserName: "System",
        assignedUserId: null,
        assignedUserName: null,
        date: "2024-03-11T00:00:00.000Z",
        title: null,
        text: "",
        status: 1,
        createdAt: "2024-03-11T16:31:37.000+00:00",
        updatedAt: "2024-03-11T16:31:37.000+00:00",
        importId: "1693691",
        documents: []
    },
    {
        id: "e784754a-4b4b-4289-b929-73310f30cafc",
        propertyId: "7bc599a1-3563-424c-8d58-d9793d83f897",
        type: "2",
        modelId: "94dd67a7-ef04-4ec8-94d8-245f970a5176",
        createdUserId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
        createdUserName: "System",
        assignedUserId: null,
        assignedUserName: null,
        date: "2024-03-11T00:00:00.000Z",
        title: null,
        text: "Email from <b>Robert St. John</b> <i>(robertwstjohn@bellsouth.net)</i><br>to <b>Architectural Committee</b><br><b>Subject:</b> MRF vote by Architectural Committee member - 306 Annslee Circle<br><br>A vote has been cast by Robert St. John for the MRF ID 31948: <b>Undecided</b>.\n\nAny notes included: \n\n",
        status: 1,
        createdAt: "2024-03-11T17:02:04.000+00:00",
        updatedAt: "2024-03-11T17:02:04.000+00:00",
        importId: "1693762",
        documents: []
    },
    {
        id: "d9b79181-b309-443c-98d2-5b329d03f36d",
        propertyId: "7bc599a1-3563-424c-8d58-d9793d83f897",
        type: "2",
        modelId: "94dd67a7-ef04-4ec8-94d8-245f970a5176",
        createdUserId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
        createdUserName: "System",
        assignedUserId: null,
        assignedUserName: null,
        date: "2024-03-11T00:00:00.000Z",
        title: null,
        text: "Email from <b>Robert St. John</b> <i>(robertwstjohn@bellsouth.net)</i><br>to <b>Architectural Committee</b><br><b>Subject:</b> MRF vote by Architectural Committee member - 306 Annslee Circle<br><br>A vote has been cast by Robert St. John for the MRF ID 31948: <b>Approved</b>.\n\nAny notes included: \n\n",
        status: 1,
        createdAt: "2024-03-11T17:02:16.000+00:00",
        updatedAt: "2024-03-11T17:02:16.000+00:00",
        importId: "1693765",
        documents: []
    },
    {
        id: "94d1852e-a027-4c72-846d-cb65d278366a",
        propertyId: "7bc599a1-3563-424c-8d58-d9793d83f897",
        type: "2",
        modelId: "94dd67a7-ef04-4ec8-94d8-245f970a5176",
        createdUserId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
        createdUserName: "System",
        assignedUserId: null,
        assignedUserName: null,
        date: "2024-03-11T00:00:00.000Z",
        title: null,
        text: "A vote has been cast by Michael J. Boone for the MRF ID 31948: <b>Approved</b>.\n\nAny notes included: \n\n",
        status: 1,
        createdAt: "2024-03-11T18:07:22.000+00:00",
        updatedAt: "2024-03-11T18:07:22.000+00:00",
        importId: "1693800",
        documents: []
    },
    {
        id: "aabbb9c6-e35c-49b4-95ab-de6b9dd95f96",
        propertyId: "7bc599a1-3563-424c-8d58-d9793d83f897",
        type: "2",
        modelId: "94dd67a7-ef04-4ec8-94d8-245f970a5176",
        createdUserId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
        createdUserName: "System",
        assignedUserId: null,
        assignedUserName: null,
        date: "2024-03-11T00:00:00.000Z",
        title: null,
        text: "A vote has been cast by Lois R Dubiel for the MRF ID 31948: <b>Approved</b>.\n\nAny notes included: \n\n",
        status: 1,
        createdAt: "2024-03-11T19:29:55.000+00:00",
        updatedAt: "2024-03-11T19:29:55.000+00:00",
        importId: "1693817",
        documents: []
    },
    {
        id: "9411f8db-17df-434d-b27f-0666552112ae",
        propertyId: "7bc599a1-3563-424c-8d58-d9793d83f897",
        type: "2",
        modelId: "94dd67a7-ef04-4ec8-94d8-245f970a5176",
        createdUserId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
        createdUserName: "System",
        assignedUserId: null,
        assignedUserName: null,
        date: "2024-03-12T00:00:00.000Z",
        title: null,
        text: "Email from <b>Nicole Miller</b> <i>(nicole.miller@libertycm.com)</i><br>to <b>Thomas Edward Darity</b> <i>(darityreporting@hotmail.com)</i> <br><b>Subject:</b> Modification application decision for 306 Annslee Circle<br><br><p>Re: Modification at 306 Annslee Circle in the Annslee Lakes subdivision</p>Dear Thomas Edward Darity,<br><br>Thank you for submitting a Modification Request form. Your <b>Replace Fence - Already Completed </b> submission has been <b>Approved</b>. Please complete the modification within the time allowed by your covenants. If you have any further changes that were not noted in the original request, please submit a new request form before commencing.<br><br>Please see the last page of the attached file for any notes and/or conditions from the ACC/ARC/Board of Directors<br><br>Regards,<br>Nicole Miller<br>Liberty Community Management, Inc.<br/><br/>\n<p><a href='https://www.libertycm.com/view.phpid1748081&token5b8ee5af8e4df438f1c3>Darity HOA Modification  306 Annslee Circle 3-8-24.pdf</a></p>",
        status: 1,
        createdAt: "2024-03-12T10:05:47.000+00:00",
        updatedAt: "2024-03-12T10:05:47.000+00:00",
        importId: "1693973",
        documents: []
    }
]

export const SAMPLE_LOG: IAssociationLog[] = [
    {
      id: "912f6372-930a-423d-b570-1b36e4f280f9",
      userId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
      userName: "System",
      verb: "imported",
      objectType: "unit",
      objectId: "142cf6e9-7b14-43cc-89ae-24347d7594a9",
      objectName: '',
      ipAddress: null,
      result: null,
      createdAt: "2024-03-23T23:21:45.815+00:00"
    },
    {
      id: "912f6372-930a-423d-b570-1b36e4f280f9",
      userId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
      userName: "System",
      verb: "imported",
      objectType: "unit",
      objectId: "142cf6e9-7b14-43cc-89ae-24347d7594a9",
      objectName: '',
      ipAddress: null,
      result: null,
      createdAt: "2024-03-24T23:21:45.815+00:00"
    },
    {
      id: "912f6372-930a-423d-b570-1b36e4f280f9",
      userId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
      userName: "System",
      verb: "imported",
      objectType: "unit",
      objectId: "142cf6e9-7b14-43cc-89ae-24347d7594a9",
      objectName: '',
      ipAddress: null,
      result: null,
      createdAt: "2024-03-25T25:21:45.815+00:00"
    },
    {
      id: "912f6372-930a-423d-b570-1b36e4f280f9",
      userId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
      userName: "System",
      verb: "imported",
      objectType: "unit",
      objectId: "142cf6e9-7b14-43cc-89ae-24347d7594a9",
      objectName: '',
      ipAddress: null,
      result: null,
      createdAt: "2024-03-26T23:21:45.815+00:00"
    },
    {
      id: "912f6372-930a-423d-b570-1b36e4f280f9",
      userId: "4933c143-cae0-4e1a-a7eb-bcbb7e3dcd5c",
      userName: "System",
      verb: "imported",
      objectType: "unit",
      objectId: "142cf6e9-7b14-43cc-89ae-24347d7594a9",
      objectName: '',
      ipAddress: null,
      result: null,
      createdAt: "2024-03-27T23:21:45.815+00:00"
    }
  ];

  export const SAMPLE_FILE = [
    {
        id: "7e541372-ff1e-4a9e-9475-832e819ca758",
        propertyId: "6368568e-fa93-4f10-b717-a4356f148db4",
        model: null,
        modelId: null,
        groupId: null,
        groupName: null,
        roomId: null,
        roomName: null,
        customerId: null,
        customerName: null,
        userId: null,
        userName: null,
        type: "F",
        subtype: null,
        extension: "PDF",
        mimeType: "application/pdf",
        size: 276112,
        url: "https://manager.libertycm.com/restored/F_eclp_220813_1631551215.7848.pdf",
        thumbUrl: null,
        path: "documents",
        name: "eclp bylaws.pdf",
        diskName: "F_eclp_220813_1631551215.7848.pdf",
        date: "2021-09-13T00:00:00.000Z",
        memo: "",
        isOptimized: false,
        isPrivate: false,
        isFavorite: false,
        status: 1,
        createdAt: "2024-03-23T23:40:58.200+00:00",
        updatedAt: "2024-03-23T23:40:58.200+00:00",
        importId: "1305735",
        documentFolderType: "document",
        statusName: "Active"
    },
    {
        id: "7e541372-ff1e-4a9e-9475-832e819ca758",
        propertyId: "6368568e-fa93-4f10-b717-a4356f148db4",
        model: null,
        modelId: null,
        groupId: null,
        groupName: null,
        roomId: null,
        roomName: null,
        customerId: null,
        customerName: null,
        userId: null,
        userName: null,
        type: "F",
        subtype: null,
        extension: "PDF",
        mimeType: "application/pdf",
        size: 276112,
        url: "https://manager.libertycm.com/restored/F_eclp_220813_1631551215.7848.pdf",
        thumbUrl: null,
        path: "documents",
        name: "My Canvases Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        diskName: "F_eclp_220813_1631551215.7848.pdf",
        date: "2021-09-13T00:00:00.000Z",
        memo: "",
        isOptimized: false,
        isPrivate: false,
        isFavorite: false,
        status: 1,
        createdAt: "2024-03-23T23:40:58.200+00:00",
        updatedAt: "2024-03-23T23:40:58.200+00:00",
        importId: "1305735",
        documentFolderType: "document",
        statusName: "Active"
    },
    {
        id: "7e541372-ff1e-4a9e-9475-832e819ca758",
        size: 276112,
        url: "https://manager.libertycm.com/restored/F_eclp_220813_1631551215.7848.docx",
        thumbUrl: null,
        path: "documents",
        name: "eclp bylaws.pdf",
        diskName: "F_eclp_220813_1631551215.7848.pdf",
        date: "2021-09-13T00:00:00.000Z",
        memo: "",
        isOptimized: false,
        isPrivate: false,
        isFavorite: false,
        status: 1,
        createdAt: "2024-03-23T23:40:58.200+00:00",
        updatedAt: "2024-03-23T23:40:58.200+00:00",
        importId: "1305735",
        documentFolderType: "document",
        statusName: "Active"
    },
    {
        id: "30ed2ef9-232e-4816-b43a-87f721ab7333",
        propertyId: "6368568e-fa93-4f10-b717-a4356f148db4",
        model: null,
        modelId: null,
        groupId: null,
        groupName: null,
        roomId: null,
        roomName: null,
        customerId: null,
        customerName: null,
        userId: null,
        userName: null,
        type: "F",
        subtype: null,
        extension: "PDF",
        mimeType: "application/pdf",
        size: 1400961,
        url: "https://manager.libertycm.com/restored/F_eclp_893229_1631551215.8124.xlsx",
        thumbUrl: null,
        path: "documents",
        name: "eclp convenants.pdf",
        diskName: "F_eclp_893229_1631551215.8124.pdf",
        date: "2021-09-13T00:00:00.000Z",
        memo: "",
        isOptimized: false,
        isPrivate: false,
        isFavorite: false,
        status: 1,
        createdAt: "2024-03-23T23:40:58.212+00:00",
        updatedAt: "2024-03-23T23:40:58.212+00:00",
        importId: "1305736",
        documentFolderType: "document",
        statusName: "Active"
    },
    {
        id: "7e541372-ff1e-4a9e-9475-832e819ca758",
        propertyId: "6368568e-fa93-4f10-b717-a4356f148db4",
        model: null,
        modelId: null,
        groupId: null,
        groupName: null,
        roomId: null,
        roomName: null,
        customerId: null,
        customerName: null,
        userId: null,
        userName: null,
        type: "F",
        subtype: null,
        extension: "PDF",
        mimeType: "application/pdf",
        size: 276112,
        url: "https://manager.libertycm.com/restored/F_eclp_220813_1631551215.7848.pdf",
        thumbUrl: null,
        path: "documents",
        name: "eclp bylaws.pdf",
        diskName: "F_eclp_220813_1631551215.7848.pdf",
        date: "2021-09-13T00:00:00.000Z",
        memo: "",
        isOptimized: false,
        isPrivate: false,
        isFavorite: false,
        status: 1,
        createdAt: "2024-03-23T23:40:58.200+00:00",
        updatedAt: "2024-03-23T23:40:58.200+00:00",
        importId: "1305735",
        documentFolderType: "document",
        statusName: "Active"
    },
  ]

  export const SAMPLE_LETTER = [
    {
        id: "3ab88703-8772-4e08-8ede-9ee7e6fe4e6e",
        propertyId: "d4ab6f61-c3ca-4c0e-a4cb-222a56b932b5",
        violationId: "630f84a5-430b-45f1-813e-875f422a01f2",
        subcategoryId: "480b8169-3d81-4721-ba15-30fa383c03f6",
        templateId: "36352971-cbe1-4691-9ab0-87dce7d9401a",
        templateName: "Friendly",
        userId: "60e6be7c-4f86-4bf8-aae4-3fdbc26ec9dc",
        userName: "Malorie F McKissick",
        date: "2024-07-23T00:00:00.000Z",
        time: "15:03:58",
        text: null,
        status: 1,
        createdAt: "2024-07-23T15:03:58.000+00:00",
        updatedAt: "2024-07-23T15:03:58.000+00:00",
        importId: "607752"
      }
  ]